import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import Checkout from '../methods/auctions/checkout'
Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_auction_uid'
  return false unless el?
  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      lots: []
      finished: undefined
      cancel: undefined
      paid: undefined
      other_payment: undefined
      commission: 0
      trm: undefined
      customer_address: undefined
      colombia: 47
      are_m: undefined
      m_delivery: undefined
    }
    methods: {
      Checkout
    }
    mounted: ->
      {id, trm, uid} = document.getElementById('initial_auction_uid').dataset
      query = $.get "/customers/#{uid}/auctions/#{id}.json"
      query.done (res) =>
        @lots = res.lots
        @finished = res.finished
        @cancel = res.cancel
        @paid = res.paid
        @other_payment = res.other_payment
        @commission = res.commission
        @trm = res.trm
        @customer_address = res.customer_address
        @are_m = res.are_m
        @m_delivery = res.m_delivery
    computed: {
      total_items: ->
        {lots} = @
        return 0 if Object.keys(lots).length == 0
        acum = 0
        for item in @lots
          acum += 1
        acum
      total: ->
        {lots} = @
        return 0 if Object.keys(lots).length == 0
        acum = 0
        for item in @lots
          if item.won_bid > 0
            acum += item.won_bid
        acum
      t_commission: ->
        {lots, commission} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        comm = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        comm = total * commission
      t_commission_usd: ->
        {lots, commission, trm} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        comm = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        comm = parseFloat(((total * commission) / trm).toPrecision(3))
      t_delivery: ->
        {lots, are_m, m_delivery, colombia, customer_address} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        delivery = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        if total > parseInt(m_delivery.free_shipping)
          delivery = 0
        else
          if are_m
            delivery = parseInt(m_delivery.local)
          else if customer_address.country.idcountry == colombia
            delivery = parseInt(m_delivery.nacional)
          else
            delivery = parseInt(m_delivery.international)
        delivery
      t_delivery_usd: ->
        {lots, are_m, m_delivery, colombia, customer_address, trm} = @
        return 0 if Object.keys(lots).length == 0
        total = 0
        delivery = 0
        for item in @lots
          if item.won_bid > 0
            total += item.won_bid
        if total > parseInt(m_delivery.free_shipping)
          delivery = 0
        else
          if are_m
            delivery = parseInt(m_delivery.local)
          else if customer_address.country.idcountry == colombia
            delivery = parseInt(m_delivery.nacional)
          else
            delivery = parseInt(m_delivery.international)
        parseFloat((delivery / trm).toPrecision(3))
      total_usd: ->
        {lots} = @
        return 0 if Object.keys(lots).length == 0
        acum = 0
        for item in @lots
          if item.won_bid_usd > 0
            acum += parseFloat item.won_bid_usd
        acum
    }
  }
