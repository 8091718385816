import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (uid, trm, auction) ->
  {lots} = @
  return false if !lots

  authenticity_token = $('[name="csrf-token"]')[0].content
  final_data = {
    lots: {
      lots_items: @lots,
      trm: trm,
      auction: auction
    },
    authenticity_token
  }
  AxiosRequest("/customers/#{uid}/lots/checkout", {final_data...}, 'post').then ({data}) =>
    window.location.replace(data.url)
